/*
 * @Author: jason
 * @Date: 2024-11-07 09:30:41
 * @LastEditTime: 2024-12-24 16:09:31
 * @LastEditors: jason
 * @Description:
 * @FilePath: \lonsdor-center-backend\src\utils\func.ts
 *
 */
export { cloneDeep, isNumber, isString, isPlainObject } from "lodash";
import { isPlainObject } from "lodash";
import { v4 } from "uuid";
import envConfig from "@/config/env";

import Ajv from "ajv";
let ajv = new Ajv();

export interface DynObject {
  [key: string]: any;
}

export interface IPageingFn<T = any> {
  dataList: T[];
  page: number;
  pageSize: number;
}

export interface IIsExceedPage {
  page: number;
  total: number;
  pageSize: number;
}

export const getUuid = v4;
// 判断是否为对象
export function isObjectTypeFn(param) {
  return Object.prototype.toString.call(param) === "[object Object]";
}

// 分页
export function pageingFn<T>(options: IPageingFn<T>): T[] {
  let { dataList, page, pageSize } = options;
  let start = (page - 1) * pageSize;
  let end = start + pageSize;

  return dataList.slice(start, end);
}

// 判断当前页数是否超出
export function isExceedPage(options: IIsExceedPage) {
  let { page, total, pageSize } = options;
  return (page - 1) * pageSize > total;
}

// 数组添加一个key
export function arrAddKeyFn(arr: any[], key: string, value: any = "") {
  return arr.map(item => {
    item[key] = value;
    return item;
  });
}

// 判断是否为数组
export function isArray(value) {
  return Array.isArray(value);
}

// 判断是否为对象
export function isObject(value) {
  return (
    typeof value === "object" &&
    value !== null &&
    Object.prototype.toString.call(value) === "[object Object]"
  );
}

// 为数组增加一个key uuid
export function arrAddKeyUuidFn(arr, key) {
  return arr.map(item => {
    item[key] = v4();
    return item;
  });
}

// 生成图片预览图
export function generateImagePreview(file) {
  return URL.createObjectURL(file);
}

// 拼接oss图片地址
export function getOssImageUrl(url) {
  if (url) {
    return `${envConfig.ossDomain}/${url}`;
  }
  return "";
}

export function isValidateJson(json: unknown) {
  return ajv.compile({ type: "object" })(json) || Array.isArray(json);
}

export function validator(json: unknown) {
  let retVal = [];

  let res = ajv.compile({ type: "object" })(json);

  if (!res) {
    retVal.push({
      path: [],
      message: "格式有误、必须json格式",
      severity: "error"
    });
  }

  return retVal;
}

/**
 * @description: 获取url最后一个路径
 * @param {*} url
 * @return {*} string
 */
export function getLastPathSegment(url) {
  const segments = url.split("/");
  return segments[segments.length - 1];
}

export function toJson(strParam) {
  let retVal = strParam;

  if (typeof strParam === "string") {
    try {
      retVal = JSON.parse(strParam);
    } catch (error) {
      retVal = {};
    }
  }

  return retVal;
}

export function jsonToString(json) {
  let retVal = json;

  if (isPlainObject(retVal) || Array.isArray(retVal)) {
    try {
      retVal = JSON.stringify(json);
    } catch (error) {
      retVal = "";
    }
  }

  return retVal;
}

export function initParams(params) {
  let nParams = {};
  if (params) {
    try {
      nParams = JSON.parse(params);
    } catch (error) {
      nParams = {};
    }
  }

  return nParams;
}

// 动态加载css
export const loadFontIconCSS = (
  cssUrl: string,
  { classNames } = { classNames: [] }
) => {
  var link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = cssUrl;
  link.classList.add(...classNames);
  document.getElementsByTagName("head")[0].appendChild(link);
};

// 动态加载css
export const loadFontIconJS = (jsUrl, { classNames } = { classNames: [] }) => {
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = jsUrl;
  script.classList.add(...classNames);
  document.getElementsByTagName("head")[0].appendChild(script);
};

export function merge(...arr: DynObject[]): DynObject {
  return Object.assign({}, ...arr);
}

// 计算psn
export function calcPsn(psn) {
  let crc: any = crc6_itu(psn);
  crc = crc < 10 ? "0" + crc : crc;
  let code = psn + crc;
  return code;
}

/**
 * [crc6_itu 生成6位2进制数值的校验码]
 * @param  [str] $data [description]
 * @return [int]       [63]
 */
export function crc6_itu(data) {
  var crc = 0x00; // Initial value
  var length = data.length;
  var j = 0;
  while (length--) {
    crc ^= data[j].charCodeAt(); // crc ^= *data; data++;
    for (let i = 0; i < 8; i++) {
      if (crc & 1)
        //与位运算
        crc = (crc >> 1) ^ 0x30; // 0x30 = (reverse 0x03)>>(8-6)
      else crc = crc >> 1;
    }
    j++;
  }
  return crc;
}

// 如果是778模板去掉第三项
export function formatTemplate_778(templateList) {
  templateList.forEach(item => {
    if (item.value.data.template === 778) {
      item.value.data.items.splice(2, 1);
    }
  });
}

/**
 * 下载文件到本地
 * @param {string|Blob|ArrayBuffer} content - 文件内容（支持字符串、Blob、ArrayBuffer等格式）
 * @param {string} fileName - 文件名（包含扩展名，例如："file.txt"）
 * @param {string} [mimeType] - MIME 类型（例如："text/plain"）
 */
export function downLoadUrlFile(content, fileName, mimeType) {
  // 创建 Blob 对象
  let blob;
  if (content instanceof Blob) {
    blob = content;
  } else if (content instanceof ArrayBuffer) {
    blob = new Blob([new Uint8Array(content)], { type: mimeType });
  } else {
    blob = new Blob([content], { type: mimeType });
  }

  // 创建临时链接
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.style.display = "none";

  // 触发下载
  document.body.appendChild(link);
  link.click();

  // 清理资源
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

// 拼接 前缀
export function addPrefix(url) {
  return url ? `${envConfig.appCdn}/${url}` : url;
}

export function getFileNameFromPath(filePath) {
  // 使用正则表达式提取文件名
  const fileName = filePath.split("/").pop().split("\\").pop();
  return fileName;
}
